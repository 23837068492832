import { INewAccessToken, INewAccessTokenDTO } from '../interfaces/new-access-token.interface';
import { IUser, IUserDTO } from '../interfaces/user.interface';

export class UserMapper {
  static fromDTO(dto: IUserDTO): IUser {
    return {
      firstName: dto.firstName,
      lastName: dto.lastName,
      companyName: dto.companyName,
    };
  }
}

export class AccessTokenMapper {
  static mapNewAccessTokenDTOToINewAccessToken(newAccessTokenDto: INewAccessTokenDTO): INewAccessToken {
    return {
      accessToken: newAccessTokenDto.token,
    };
  }
}
