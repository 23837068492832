import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../@core/api/api.service';
import { appRoutes } from '../../../../shared/constants/routes.constants';
import { IConfirmEmailPayload } from '../interfaces/confirm-email.interface';
import { ILoginLinkedinPayload, ILoginLinkedinResponseDTO } from '../interfaces/login-linkedin.interface';
import { ILoginPayload, ILoginResponseDTO } from '../interfaces/login.interface';
import { IRegisterPayload } from '../interfaces/register.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthController {
  private readonly _path = '/account';

  constructor(private apiService: ApiService) {}

  login(payload: ILoginPayload): Observable<ILoginResponseDTO> {
    return this.apiService.post<ILoginResponseDTO>(`${this._path}/login`, payload);
  }

  loginWithLinkedIn(authorizationCode: ILoginLinkedinPayload): Observable<ILoginLinkedinResponseDTO> {
    return this.apiService.post<ILoginLinkedinResponseDTO>(`${this._path}/sso/linkedin`, authorizationCode);
  }

  register(userAccountForRegister: IRegisterPayload): Observable<boolean> {
    return this.apiService.post(`${this._path}/${appRoutes.REGISTER}`, userAccountForRegister);
  }

  confirmEmail(token: IConfirmEmailPayload): Observable<boolean> {
    return this.apiService.put(`${this._path}/confirm-account`, token);
  }

  logout(): Observable<boolean> {
    return this.apiService.delete(`${this._path}/${appRoutes.LOGOUT}`);
  }
}
