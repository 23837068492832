import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private readonly _accessTokenKey = 'accessToken';
  private readonly _refreshTokenKey = 'refreshToken';

  setCurrentAccessToken(token: string): void {
    localStorage.setItem(this._accessTokenKey, token);
  }

  static getCurrentAccessToken(): string {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem('accessToken');
    }
    return null;
  }

  setCurrentRefreshToken(token: string): void {
    localStorage.setItem(this._refreshTokenKey, token);
  }

  static getCurrentRefreshToken(): string {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem('refreshToken');
    }
    return null;
  }

  clearCurrentAccessToken(): void {
    localStorage.removeItem(this._accessTokenKey);
  }

  clearCurrentRefreshToken(): void {
    localStorage.removeItem(this._refreshTokenKey);
  }

  clearTokens(): void {
    this.clearCurrentAccessToken();
    this.clearCurrentRefreshToken();
  }
}
