export function trimRequestBody(obj: any): any {
  if (typeof obj === 'string') {
    return obj.trim() || null;
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = trimRequestBody(obj[key]);
      }
    }
  }
  return obj;
}
