import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { appRoutes } from '../../shared/constants/routes.constants';

import { Router } from '@angular/router';
import { AccountController } from '../api/account.api';
import { AccessTokenMapper, UserMapper } from '../api/account.mapper';
import { INewAccessToken, INewAccessTokenDTO, INewAccessTokenPayload } from '../interfaces/new-access-token.interface';
import { IUser, IUserDTO } from '../interfaces/user.interface';
import { GlobalLoadingService } from './global-loading.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private _currentUserSubject$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);

  readonly loginRoute = appRoutes.LOGIN;

  constructor(
    private accountController: AccountController,
    private tokenService: TokenService,
    private loadingService: GlobalLoadingService,
    private router: Router,
  ) {}

  setUser(): void {
    if (this.getCurrentUserValue()) {
      return;
    }

    this.loadingService.show();

    this.accountController.getMe().subscribe({
      next: (response: IUserDTO) => {
        const user = UserMapper.fromDTO(response);
        this._currentUserSubject$.next(user);
      },
      error: (error) => {
        console.error('Get user error:', error);
        this._currentUserSubject$.next(null);
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }

  getCurrentUser(): Observable<IUser> {
    return this._currentUserSubject$.asObservable();
  }

  getCurrentUserValue(): IUser {
    return this._currentUserSubject$.getValue();
  }

  clearUser(): void {
    this._currentUserSubject$.next(null);
  }

  refreshAccessToken(payload: INewAccessTokenPayload): Promise<INewAccessToken> {
    return new Promise<INewAccessToken>((resolve) => {
      this.accountController.getNewAccessToken(payload).subscribe({
        next: (response: INewAccessTokenDTO) => {
          const newAccessToken = AccessTokenMapper.mapNewAccessTokenDTOToINewAccessToken(response);
          this.tokenService.setCurrentAccessToken(newAccessToken.accessToken);
          resolve(newAccessToken);
        },
        error: () => {
          resolve(null);
        },
      });
    });
  }
}
