import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { trimRequestBody } from '../helpers/trim-request.helper';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly _apiUrl = environment.server;

  constructor(private http: HttpClient) {}

  get<T>(path: string, params = {}, headers = {}): Observable<T> {
    return this.http.get<T>(`${this._apiUrl}${path}`, {
      params: params,
      headers: headers,
    });
  }

  put<T>(path: string, body = {}, params = {}): Observable<T> {
    return this.http.put<T>(`${this._apiUrl}${path}`, trimRequestBody(body), {
      params: params,
    });
  }

  post<T>(path: string, body: any = {}, params: any = {}, headers = {}): Observable<T> {
    return this.http.post<T>(`${this._apiUrl}${path}`, trimRequestBody(body), {
      params: params,
      headers: headers,
    });
  }

  patch<T>(path: string, body: any = {}, params: any = {}, headers = {}): Observable<T> {
    return this.http.patch<T>(`${this._apiUrl}${path}`, trimRequestBody(body), {
      params: params,
      headers: headers,
    });
  }

  delete<T>(path: string, params = {}): Observable<T> {
    return this.http.delete<T>(`${this._apiUrl}${path}`, { params: params });
  }
}
